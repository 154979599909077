<template>
    <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="treeSelectChange"></GnvqTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>报名列表</span>
                        <div style="float: right; padding: 0px 0">
                            <el-link href="../..//BMImportModelv1.xlsx" icon="el-icon-download" :underline="false" target="_blank" style="margin-right: 10px">下载报名模板</el-link>

                            <el-button-group>
                                <el-button v-if="hasPerm('enroll:import')" type="success" round size="mini" icon="el-icon-plus" @click="toImport">报名信息导入</el-button>
                                <el-dropdown v-if="hasPerm('enroll:export')"  @command="dropdownOperate">
                                    <el-button icon="el-icon-download" type="success" round size="mini">
                                        导出<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="dropdownValue('export', '')" icon="el-icon-download">导出报名信息(Excel)</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportpdf', '')" icon="el-icon-download">导出报名资料(PDF)</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportTmpl', '')" icon="el-icon-download">导出报名资料(模板)</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportCertImg', '')" icon="el-icon-download">导出证件照片</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportEnrollImg', '')" icon="el-icon-download">导出报名照片(高级)</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportDefImg', '')" icon="el-icon-download">导出自定义图片(高级)</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportIdcard', '')" icon="el-icon-download">导出身份证</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportBankno', '')" icon="el-icon-download">导出银行卡</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('exportJieyeImg', '')" icon="el-icon-download">导出结业证书</el-dropdown-item>
                                        <el-dropdown-item divided :command="dropdownValue('exportByClass', '')" icon="el-icon-download">批量导出考生报名资料（图片和PDF）</el-dropdown-item>
                                        <el-dropdown-item divided :command="dropdownValue('classArchive', '')" icon="el-icon-download">班级培训档案</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('userArchive', '')" icon="el-icon-download">学员培训档案</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-button-group>
                        </div>
                    </div>
                    <!-- 列表查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="">
                                            <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable style="width: 140px"></el-input>
                                        </el-form-item>
                                        <el-form-item style="width: 150px"  v-if="this.privRange.havePriv()">
                                            <el-select v-model="queryParams.companyId"  placeholder="请选择报名机构" @change="getClassList" filterable clearable>
                                                <el-option value="">请选择</el-option>
                                                <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item style="width: 120px">
                                            <el-select filterable v-model="queryParams.classId" placeholder="请选择班级" clearable>
                                                <el-option value="">请选择</el-option>
                                                <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label=""  style="width: 230px">
                                            <el-date-picker  style="width: 100%;"
                                                v-model="queryParams.startEndDate"
                                                type="daterange"
                                                align="right"
                                                unlink-panels
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                :picker-options="pickerOptions">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                        </el-form-item>
                                        <el-button type="text"  @click="showMore=!showMore">更多条件</el-button>
                                        <div style="float: right">
                                            <el-dropdown  @command="dropdownOperate">
                                                <el-button icon="el-icon-s-operation" round>
                                                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                                                </el-button>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item :command="dropdownValue('toIdentify', '')" icon="el-icon-edit">鉴定机构录入</el-dropdown-item>
<!--                                                    <el-dropdown-item :command="dropdownValue('toExamRemain', '')" icon="el-icon-edit">考试通知</el-dropdown-item>-->
                                                    <el-dropdown-item v-if="hasPerm('enroll:delete')"  :command="dropdownValue('toDelete', '')" icon="el-icon-delete">批量删除</el-dropdown-item>
                                                    <el-dropdown-item v-if="hasPerm('enroll:import')" :command="dropdownValue('toImportUpdate', '')" icon="el-icon-edit">导入信息修改</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row v-show="showMore" style="margin-top: 10px">
                                    <el-col :span="24">
                                        <el-form-item style="width: 120px">
                                            <el-select v-model="queryParams.enrollType" placeholder="报名方式" clearable>
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="个人报名" value="SELF"></el-option>
                                                <el-option label="公司代报名" value="COMPANY"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item style="width: 120px">
                                            <el-select v-model="queryParams.payWay" placeholder="支付类型" clearable>
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="微信支付" value="1"></el-option>
                                                <el-option label="支付宝支付" value="2"></el-option>
                                                <el-option label="线下支付" value="3"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item style="width: 100px">
                                            <el-select v-model="queryParams.payStatus" placeholder="支付状态" clearable>
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="未支付" value="0"></el-option>
                                                <el-option label="已支付" value="1"></el-option>
                                                <el-option label="退款中" value="2"></el-option>
                                                <el-option label="已退款" value="3"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item style="width: 100px">
                                            <el-select v-model="queryParams.confirmStatus" placeholder="审核状态" clearable>
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="未审核" value="0"></el-option>
                                                <el-option label="已审核" value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item style="width: 80px">
                                            <el-select v-model="queryParams.dataStatus" placeholder="暂存状态" clearable>
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="暂存" value="0"></el-option>
                                                <el-option label="正常" value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item style="width: 100px">
                                            <el-select v-model="queryParams.deleteStatus" placeholder="删除状态" clearable>
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="正常" value="0"></el-option>
                                                <el-option label="已删除" value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item style="width: 100px">
                                            <el-select v-model="queryParams.identifyStatus" placeholder="鉴定状态" clearable>
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="未鉴定" value="0"></el-option>
                                                <el-option label="已鉴定" value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                highlight-current-row
                                style="width: 100%;"
                                ref="queryTable"
                                height="calc(100vh - 340px)"
                                @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    type="selection"
                                    width="50">
                                </el-table-column>

                                <el-table-column>
                                    <template slot-scope="{row}">
                                        <div class="table-row">
                                            <div class="center">
                                                <div class="header">
                                                    <div class="item">
                                                        <span class="value strong">{{ row.trueName }}</span>
                                                    </div>
                                                    <div class="item">
                                                        <span class="label">性别：</span>
                                                        <span class="value">{{
                                                                row.sex | sexFilter
                                                            }}</span>
                                                    </div>
                                                    <div class="item">
                                                        <span class="label">身份证号：</span>
                                                        <span class="value">{{ row.idCard }}</span>
                                                    </div>
                                                    <div class="item">
                                                        <span class="label">联系电话：</span>
                                                        <span class="value">{{ row.phone }}</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">工种等级：</span>
                                                            <span class="value">{{ row.gnvqName }}-{{ row.levelName }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">报名机构：</span>
                                                            <span class="value">{{ row.companyName }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">班级：</span>
                                                            <span class="value">
                                                                {{row.className}}
                                                            </span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">报名时间：</span>
                                                            <span class="value">
                                                                {{row.createTime}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">学历：</span>
                                                            <span class="value">{{ row.educationName }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">报名方式：</span>
                                                            <span class="value">{{
                                                                    row.enrollType | enrollTypeFilter
                                                                }} / {{ row.classType | classTypeFilter}}
                                                                <template v-if="row.enrollType==='COMPANY'">
                                                                    - <el-tag>
                                                                    {{ row.userId === '999999' ? '未关联' : '已关联'}}
                                                                </el-tag>
                                                                </template>
                                                            </span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">鉴定机构：</span>
                                                            <span class="value">{{ row.identifyOrgName }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">鉴定日期：</span>
                                                            <span class="value">{{ row.identifyDate | parseTime('{y}-{m}-{d}')}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="item-info">
                                                        <div class="item">
                                                            <span class="label">报名金额：</span>
                                                            <span class="value">{{ row.priceValue }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">缴费金额：</span>
                                                            <span class="value">{{ row.payValue }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">支付方式：</span>
                                                            <span class="value">{{ row.payWay | payWayFilter }}</span>
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">支付状态：</span>
                                                            <span class="value">
                                                                <el-tag :type="row.payStatus | colorFilter2">
                                                                    {{ row.payStatus  | payStatusFilter}}
                                                                </el-tag>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="footer"></div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <div class="oper-col">
                                            <el-tag v-if="row.deleteStatus===1" :type="row.deleteStatus | colorFilter">
                                                {{ row.deleteStatus  | deleteStatusFilter}}
                                            </el-tag>
                                            <el-tag v-else :type="row.confirmStatus | colorFilter2">
                                                {{ row.confirmStatus  | confirmStatusFilter}}
                                            </el-tag>
                                            <el-button v-if="row.confirmStatus === 0 && row.deleteStatus === 0" type="text" size="mini"  @click="toView(row.orderId)">审核</el-button>
                                            <el-button v-if="row.deleteStatus===1 || row.confirmStatus===1" type="text" size="mini"  @click="toView(row.orderId)">查看</el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 信息查看侧边栏 -->
        <UserEnrollDtl ref="userEnrollDtl" @showEditor="showEditor" @refreshTable="initTable()"></UserEnrollDtl>
        <!-- 信息编辑侧边栏 -->
        <UserEnrollEdtor ref="userEnrollEdtor" @callback="toView" @refreshTable="initTable()"></UserEnrollEdtor>
        <!-- 【导入】对话框 -->
        <el-dialog
            title="数据导入"
            :visible.sync="importModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-row :gutter="16">
                <el-col :span="23">
                    <el-form ref="editForm" :model="info" label-position="right" label-suffix=":" :rules="ruleValidate" label-width="150px">
                        <el-form-item label="工种分类" prop="categoryId">
                            <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.categoryId" :gnvqId.sync="info.gnvqId"
                                :levelId.sync="info.levelId" @callback="getClassList2"></GnvqSelect>
                        </el-form-item>
                        <el-form-item label="培训机构" prop="companyId" v-show="this.privRange.havePriv()">
                            <el-select v-model="info.companyId" clearable  @change="getClassList2">
                                <el-option value="">请选择</el-option>
                                <el-option v-for="(item,idx) in orgList" :label="item.orgName" :key="idx" :value="item.orgId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="班级" prop="classId">
                            <el-select v-model="info.classId" clearable>
                                <el-option value="">请选择</el-option>
                                <el-option v-for="(item,idx) in classList" :label="item.yearNum + '-' + item.classNum" :key="idx" :value="item.classId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="上传文件">
                            <el-upload
                                class="upload-demo"
                                accept=".xls,.xlsx"
                                ref="upload"
                                :action="getUploadUrl()"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :on-change="fileChange"
                                :file-list="fileList"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx文件</div>
                            </el-upload>
                            <span v-if="file !== null">{{file.name}}</span>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importModal = false">取消</el-button>
                <el-button type="primary" @click="importData('editForm')" :loading = uploading>
                    {{ uploading ? '上传中' : '确认上传'}}</el-button>
            </span>
        </el-dialog>
        <!-- 【导出证件照片】对话框-->
        <el-dialog
            title="导出证件照片"
            :visible.sync="exportImgModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="exportImgInfo" label-width="150px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="命名方式" prop="sex">
                            <el-radio-group v-model="exportImgInfo.nameType">
                                <el-radio :label="0">身份证号</el-radio>
                                <el-radio :label="1">身份证号姓名</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="证件照图片名称" prop="sex">
                            <el-radio-group v-model="exportImgInfo.appendA">
                                <el-radio :label="1">带A</el-radio>
                                <el-radio :label="0">不带A</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportImgModal = false">取消</el-button>
                <el-button type="primary" @click="toExportImg()">确认导出</el-button>
            </span>
        </el-dialog>
        <!-- 【导出证件照片】对话框-->
        <el-dialog
            title="导出报名资料照片"
            :visible.sync="exportEnrollImgModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="exportImgInfo" label-width="150px" label-suffix=":">
                <el-form-item label="图片类型" prop="sex">
                    <el-radio-group v-model="exportImgInfo.exportImgType" @change="getCertNameList">
                        <el-radio label="certHeadImg">蓝底照片</el-radio>
                        <el-radio label="idcardImgFront">身份证正面</el-radio>
                        <el-radio label="idcardImgBack">身份证反面</el-radio>
                        <el-radio label="idcardImg">身份证照片（正反面）</el-radio>
                        <el-radio label="workImg">工龄证明</el-radio>
                        <el-radio label="eduImg">学历证明</el-radio>
                        <el-radio label="certImg">证书照片</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="证书类型" v-show="this.exportImgInfo.exportImgType==='certImg'">
                    <el-select v-model="exportImgInfo.exportCertName" placeholder="请选择证书类型">
                        <el-option value="">请选择学历</el-option>
                        <el-option v-for="item in certNameList" :value="item.dictName" :label="item.dictName"  :key="item.dictName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="后缀字符">
                    <el-input v-model="exportImgInfo.exportImgSuffix" placeholder="请输入后缀"></el-input>
                    <p>例如：文件名为 <span>372123138312120123</span><span style="color: red">-1</span>.jpg， 则输入<span style="color: red">-1</span> </p>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportEnrollImgModal = false">取消</el-button>
                <el-button type="primary" @click="toExportEnrollImg()">确认导出</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="导出自定义资料照片"
            :visible.sync="exportDefImgModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="exportImgInfo" label-width="150px" label-suffix=":">
                <el-form-item label="图片类型">
                    <el-select v-model="exportImgInfo.exportImgType" placeholder="请选择类型">
                        <el-option value="">请选择类型</el-option>
                        <el-option v-for="item in defFileList" :value="item.fileId" :label="item.fieldName"  :key="item.fileId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="后缀字符">
                    <el-input v-model="exportImgInfo.exportImgSuffix" placeholder="请输入后缀"></el-input>
                    <p>例如：文件名为 <span>372123138312120123</span><span style="color: red">-1</span>.jpg， 则输入<span style="color: red">-1</span> </p>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportDefImgModal = false">取消</el-button>
                <el-button type="primary" @click="exportDefImg()">确认导出</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="批量导出考生报名资料（图片和PDF）"
            :visible.sync="exportByClassModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="exportByClassInfo" label-width="150px" label-suffix=":">
                <el-form-item label="文件夹命名方式">
                    <el-radio-group v-model="exportByClassInfo.folderNameType">
                        <el-radio :label="1">姓名</el-radio>
                        <el-radio :label="0">身份证号</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportByClassModal = false">取消</el-button>
                <el-button type="primary" @click="exportByClass()">确认导出</el-button>
            </span>
        </el-dialog>
        <!-- 【鉴定机构录入】对话框 -->
        <el-dialog
            title="鉴定机构报名录入"
            :visible.sync="identifyModel"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form ref="identifyForm" :model="identifyInfo" label-width="150px" :rules="ruleIdentifyValidate" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="鉴定机构" prop="identifyOrgId">
                            <el-select filterable v-model="identifyInfo.identifyOrgId" clearable>
                                <el-option value="">请选择鉴定机构</el-option>
                                <el-option v-for="(item, index)  in identifyOrgList" :label="item.dictName" :value="item.dictId" :key="'s1' + index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报考日期" prop="identifyDate">
                            <el-date-picker
                                v-model="identifyInfo.identifyDate"
                                type="date"
                                placeholder="选择报考日期"
                                @change="identifyInfo.identifyDate=$event"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="报考批次" prop="identifyBatch">
                            <el-input v-model="identifyInfo.identifyBatch" placeholder="请输入报考批次" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="identifyModel = false">取消</el-button>
                <el-button type="primary" @click="identifySave('identifyForm')">确认保存</el-button>
            </span>
        </el-dialog>
        <!-- 【考试通知】对话框 -->
        <el-dialog
            title="考试通知"
            :visible.sync="examModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form ref="examForm" :model="examRemindInfo" label-width="150px" :rules="ruleExamValidate" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="考试时间" prop="examDate">
                            <el-input v-model="examRemindInfo.examDate" placeholder="请输入考试时间" />
                        </el-form-item>
                        <el-form-item label="考点名称" prop="addressName">
                            <el-input v-model="examRemindInfo.addressName" placeholder="请输入考点名称" />
                        </el-form-item>
                        <el-form-item label="考点地址" prop="addressDesc">
                            <el-input type="textarea" v-model="examRemindInfo.addressDesc" :rows="2" placeholder="请输入考点地址（最多100个汉字）" />
                        </el-form-item>
                        <el-form-item label="其他说明" prop="remark">
                            <el-input type="textarea" v-model="examRemindInfo.remark" :rows="2" placeholder="请输入备注说明（最多100个汉字）" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="examModal = false">取消</el-button>
                <el-button type="primary" @click="examRemainSave('examForm')">确认发送</el-button>
            </span>
        </el-dialog>
        <!-- 【导入修改】对话框 -->
        <el-dialog
            title="导入数据修改"
            :visible.sync="importEditorModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form ref="importEditForm" :model="editorInfo" label-width="150px" :rules="ruleEditValidate" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="姓名" prop="trueName">
                            <el-input v-model="editorInfo.trueName" placeholder="请输入姓名" />
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="editorInfo.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="idCard">
                            <el-input v-model="editorInfo.idCard" placeholder="请输入身份证号" @blur="checkHasEnroll" />
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="editorInfo.phone" placeholder="请输入联系电话"/>
                        </el-form-item>
                        <el-form-item label="学历" prop="educationId">
                            <el-select v-model="editorInfo.educationId" filterable placeholder="请选择学历">
                                <el-option value="">请选择</el-option>
                                <el-option v-for="item in eduList" :value="item.dictId" :label="item.dictName" :key="item.dictId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importEditorModal = false">取消</el-button>
                <el-button type="primary" @click="importUpdate('importEditForm')">确认修改</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="按模板导出报名资料"
            :visible.sync="exportTmplDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form ref="importEditForm" :model="exportTmplInfo" label-width="150px" :rules="ruleEditValidate" label-suffix=":">
                <el-row>
                    <el-col :span="22">
                        <el-form-item label="模板" prop="tmplId">
                            <el-select v-model="exportTmplInfo.tmplId" filterable placeholder="请选择模板" required @change="tmplIdChnage">
                                <el-option value="">请选择</el-option>
                                <el-option v-for="item in exportTmplList" :value="item.tmplId" :label="item.tmplName" :key="item.tmplId"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="导出文件类型">
                            <el-radio-group v-model="exportTmplInfo.fileType">
                                <el-radio label="WORD">WORD</el-radio>
                                <el-radio label="JPG">JPG</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="后缀字符" v-if="exportTmplInfo.fileType==='JPG'">
                            <el-input v-model="exportTmplInfo.exportImgSuffix" placeholder="请输入后缀"></el-input>
                            <p>例如：文件名为 <span>372123138312120123</span><span style="color: red">-承诺书</span>.jpg， 则输入<span style="color: red">-承诺书</span> </p>
                        </el-form-item>
                        <el-form-item label="覆盖水印高度" v-if="exportTmplInfo.fileType==='JPG'">
                            <el-input v-model="exportTmplInfo.warterHeight" placeholder="请输入水印高度"></el-input>
                            <p>数值，默认75，可根据实际情况调整</p>
                        </el-form-item>
                        <el-form-item label="导出方式" prop="exportType"  v-if="exportTmplInfo.fileType==='WORD'">
                            <el-radio-group v-model="exportTmplInfo.exportType">
                                <el-radio :label="1">合并导出（多人合并到一个文件中）</el-radio>
                                <el-radio :label="2">独立导出（每人一个文件）</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportTmplDialog = false">取消</el-button>
                <el-button type="primary" @click="exportTmpl('importEditForm')">确认导出</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="导出报名信息"
            :visible.sync="exportDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="600px">
            <el-form ref="importEditForm" :model="exportTmplInfo" label-width="150px" :rules="ruleEditValidate" label-suffix=":">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="报名信息">

                            <el-checkbox
                                v-for="(item, idx) in exportFieldList.normalFieldList" :key="idx"
                                v-model="item.selected">{{item.fieldName}}</el-checkbox>

                        </el-form-item>
                        <el-form-item label="扩展信息">
                            <el-checkbox
                                v-for="(item, idx) in exportFieldList.defFieldList" :key="idx"
                                v-model="item.selected">{{item.fieldName}}</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportDialog = false">取消</el-button>
                <el-button type="primary" @click="submitExport()">确认导出</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    import GnvqSelect from "@v/components/GnvqSelect";
    // import SelectEditor from "./components/SelectEditor";
    import UserEnrollEdtor from "./components/UserEnrollEdtor";
    import UserEnrollDtl from "./components/UserEnrollDtl";
    //import ImgCropperDiag from '../../components/common/ImgCropperDiag';
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree,
            GnvqSelect,
            //SingleUploadImg,
            //MutiUploadImg,
            UserEnrollEdtor,
            UserEnrollDtl,
            //ImgCropperDiag
        },
        filters: {
            dataStatusFilter(status) {
                const statusMap = {
                    1: '已提交',
                    0: '暂存',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            confirmStatusFilter(status) {
                const statusMap = {
                    1: '已审核',
                    0: '未审核',
                };
                return statusMap[status]
            },
            payStatusFilter(status) {
                const statusMap = {
                    1: '已支付',
                    0: '未支付',
                    2: '退款中',
                    3: '已退款',
                };
                return statusMap[status]
            },
            payWayFilter(status) {
                const statusMap = {
                    1: '微信支付',
                    2: '支付宝',
                    3: '线下支付'
                };
                return statusMap[status]
            },
            enrollTypeFilter(status) {
                const statusMap = {
                    'SELF': '个人',
                    'COMPANY': '公司',
                };
                return statusMap[status]
            },
            classTypeFilter(status) {
                const statusMap = {
                    'LEARN': '继续教育',
                    'ENROLL': '考试报名',
                    'NOCONFIRM': '自动加入'
                };
                return statusMap[status]
            },
            postBookFilter(status) {
                const statusMap = {
                    1: '是',
                    2: '否'
                };
                return statusMap[status]
            },
            sexFilter(status) {
                const statusMap = {
                    1: '男',
                    2: '女'
                };
                return statusMap[status]
            },
            taskTypeFilter(status) {
                const statusMap = {
                    '1000': '开放任务',
                    '2000': '内部任务'
                };
                return statusMap[status]
            },
            consumTypeFilter(status) {
                const statusMap = {
                    1: '单人领取',
                    2: '多人领取'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
            colorFilter2(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success',
                };
                return statusMap[status]
            },
        },
        data () {
            const validatePhone = (rule, value, callback) => {

                let phone_reg = new RegExp(/^\d{3}-?\d{7,8}|\d{4}-?\d{7,8}$/);
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入联系电话'));
                } else if (!phone_reg.test(value) && !(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                    callback(new Error('联系电话格式不对'));
                } else {
                    callback();
                }
            };
            const validateIdcard = (rule, value, callback) => {
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入身份证号'));
                } else if (value.length !== 18) {
                    callback(new Error('身份证号格式不对'));
                } else {
                    callback();
                }
            };
            return {
                showMore: false,
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '报名管理'
                    }
                ],
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    dataStatus: '1',
                    confirmStatus: '',
                    payWay: '',
                    payStatus: '',
                    orgId: this.appCookies.getCookieTOID(),
                    companyId: this.appCookies.getCookieOID(),
                    userId: this.appCookies.getCookieUID(),
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    identifyStatus: '',
                    startEndDate: [],
                    enrollType: '',
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                tableData: [],
                selectdata: [],
                fileList: [],
                info: {},
                orderInfo: {},
                tableLoading: false,
                openDrawer: false,

                ruleValidate: {
                    categoryId: [
                        { required: true, type: 'number', message: '请选择工种分类', trigger: 'blur' }
                    ],
                    gnvqId: [
                        { required: true, type: 'number', message: '请选择工种', trigger: 'blur' }
                    ],
                    // levelId: [
                    //     { required: true, type: 'number', message: '请选择工种等级', trigger: 'blur' }
                    // ],
                    companyId: [
                        { required: true, type: 'number', message: '请选择培训机构', trigger: 'blur' }
                    ],
                },
                file: null,
                importModal: false,
                importInfo: {},
                infoModal: false,
                orgList: [],
                classList: [],
                enrollEditorDrawer: false,
                currentOrderId: '',
                importEditorModal: false,
                editorInfo: {},
                ruleEditValidate: {
                    // 报名信息
                    trueName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    sex: [
                        { required: true, type: 'number', message: '请选择性别', trigger: 'change' }
                    ],
                    idCard: [
                        { required: true, validator: validateIdcard, trigger: 'blur' }
                    ],
                    educationId: [
                        { required: true, type: 'number', message: '请选择学历', trigger: 'change' }
                    ],
                    phone: [
                        { required: true, validator: validatePhone, trigger: 'blur' }
                    ],
                },
                eduList: [],

                identifyInfo: {},
                identifyModel: false,
                identifyOrgList: [],
                ruleIdentifyValidate: {
                    identifyOrgId: [
                        { required: true, type: 'number', message: '请选择鉴定机构', trigger: 'change' }
                    ],
                    // identifyDate: [
                    //     { required: true, message: '请选择报考日期', trigger: 'change' }
                    // ],
                    identifyBatch: [
                        { required: true, message: '请输入报考批次', trigger: 'blur' }
                    ],
                },
                examRemindInfo: {},
                examModal: false,
                ruleExamValidate: {
                    examDate: [
                        { required: true, message: '请输入考试时间', trigger: 'blur' }
                    ],
                    addressName: [
                        { required: true, message: '请输入考点名称', trigger: 'blur' }
                    ],
                    addressDesc: [
                        { required: true, message: '请输入考点地址', trigger: 'blur' }
                    ],
                },
                uploading: false,
                exportImgModal: false,
                exportEnrollImgModal: false,
                exportImgInfo: {
                    appendA: 1,
                    nameType: 0,
                    exportImgType: '',
                    exportCertName: '',
                    exportImgSuffix: '',
                },
                exportDefImgModal: false,
                defFileList: [],
                certNameList: [],
                exportTmplDialog: false,
                exportTmplList: [],
                exportTmplInfo: {
                    tmplId: '',
                    exportType: 1,
                    exportImgSuffix: '',
                    fileType: 'WORD',
                    warterHeight: 75
                },
                exportDialog: false,
                exportFieldList: {
                    normalFieldList: [],
                    defFieldList: []
                },
                exportByClassModal: false,
                exportByClassInfo: {
                    folderNameType: 1
                }
            };
        },
        mounted () {
            this.initTable();
            this.$nextTick(function() {
            });
            this.getOrgList();
            this.getEduList();
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player
            },
            playsinline () {
                let ua = navigator.userAgent.toLocaleLowerCase()
                // x5内核
                if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
                    return false
                } else {
                    // ios端
                    return true
                }
            }
        },
        methods: {
            //GnvqTree（工种等级分类）组件callback方法
            treeSelectChange: function (data) {
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.getClassList();
                this.refreshTable()
            },
            //导入
            toImport: function () {
                this.file = null;
                this.info = {
                    orgId: this.queryParams.orgId,
                    companyId: this.queryParams.companyId,
                    categoryId: this.queryParams.gnvqCatId,
                    gnvqId: this.queryParams.gnvqId,
                    levelId: this.queryParams.levelId,
                };
                this.getOrgList();
                this.importModal = true;
            },
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieTOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //导入对话框中的方法
            //GnvqSelect（工种分类）组件的callback方法：获取班级列表
            getClassList2: function () {
                if (!this.info.companyId || !this.info.levelId || !this.info.gnvqId){
                    this.classList = [];
                    return;
                }
                let data = {
                    companyId: this.info.companyId,
                    levelId: this.info.levelId,
                    gnvqId: this.info.gnvqId,
                    operUserId: this.appCookies.getCookieUID()
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //上传文件
            beforeUpload(file) {
                //文件类型
                const isType = file.type === 'application/vnd.ms-excel'
                const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                const fileType = isType || isTypeComputer
                if (!fileType) {
                    this.$message.error('上传文件只能是xls/xlsx格式！')
                }
                this.file = file;
                this.info.file = file;
                return fileType;
            },
            getUploadUrl: function () {
                return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
            },
            fileChange(file, fileList) {
                console.log(file)
                this.file = file.raw
            },
            //确认上传
            importData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        this.uploading = true;
                        let formData = new FormData();
                        formData.append('file', this.file);
                        let url = "user/enrollOrder/import";
                        this.info.orderId = ''
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;

                        formData.append("info", new Blob([JSON.stringify(this.info)], { type: "application/json" }));
                        // 保存数据
                        this.apis.postFile(url, formData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.importModal = false;
                                    this.importInfo = res.data;
                                    this.$message({
                                        message: '导入成功，共导入' + this.importInfo.rightCount.toString() + '条记录',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    if (this.importInfo.wrongCount > 0) {
                                        this.infoModal = true;
                                    }
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                                this.uploading = false;
                            }).catch(err => {
                            this.uploading = false;
                            console.log(err);
                        });
                    }
                })
            },
            //导出
            toExport: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                let data = {
                    classId: this.queryParams.classId
                }
                this.apis.get('user/enrollOrder/getExportField', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.exportFieldList = res.data || {};
                            this.exportDialog = true;
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            exportByClass: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.exportByClassInfo.classId = this.queryParams.classId
                this.exportByClassInfo.userId = this.appCookies.getCookieUID()
                this.apis.postJson('user/enrollOrder/exportByClass', this.exportByClassInfo)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.exportByClassModal = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'error'
                            });
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            submitExport: function () {
                this.exportDialog = false;
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                let data = this.queryParams;
                data.exportFieldList = this.exportFieldList;
                data.orderIds = this.getSelectionIds();
                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.apis.axios()({
                    method: 'post',
                    url: 'user/enrollOrder/exportV2',
                    data: this.$http.adornParams(data),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    console.log(err);
                    this.mainLoading = false;
                });
            },
            //获取表格被选中列的id
            getSelectionIds: function () {
                if (this.selectdata.length === 0) {
                    return [];
                }
                let orderIds = [];
                this.selectdata.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                return orderIds
            },
            handleSelectionChange(val) {
                this.selectdata = val;
            },
            //导出报名资料
            toExportPdf: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在创建下载任务，请稍等';
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.apis.postJson('user/enrollOrder/downloadPdf', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.importEditorModal = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                        this.mainLoading = false;
                    }).catch(err => {
                    console.log(err);
                });

                // this.apis.axios()({
                //     method: 'post',
                //     url: 'user/enrollOrder/downloadPdf',
                //     data: this.$http.adornParams(data),
                //     'responseType': 'blob'
                // }, false, 'json').then((res) => {
                //     if (res.type === 'application/json') {
                //         this.$message({
                //             message: '未查询到数据',
                //             showClose: true,
                //             offset: 200,
                //             type: 'error'
                //         });
                //         this.mainLoading = false;
                //         return false
                //     }
                //     let date = new Date();
                //     let year = date.getFullYear();
                //     let timestamp = date.valueOf();
                //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                //     this.mainLoading = false;
                // }).catch(err => {
                //     console.log(err);
                //     this.mainLoading = false;
                // });
            },
            toExportTmpl: function () {
                if (!this.queryParams.companyId) {
                    this.$message({
                        message: '请选择报名机构',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.exportTmplInfo.tmplId = ''
                this.exportTmplInfo.exportType = 1
                this.apis.get('course/enroll/export/tmpl/getList?orgId=' + this.queryParams.companyId)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.exportTmplList = res.data || [];
                            this.exportTmplDialog = true;
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            exportTmpl: function () {
                if (!this.exportTmplInfo.tmplId) {
                    this.$message({
                        message: '请选择模板',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                if (!this.exportTmplInfo.fileType) {
                    this.$message({
                        message: '请选择导出文件格式',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                // exportImgSuffix

                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (data.orderIds.length === 0) {
                    this.$message({
                        message: '请选择要导出的记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                // if (data.orderIds.length > 50) {
                //     this.$message({
                //         message: '每次最多导出10条记录',
                //         showClose: true,
                //         offset: 200,
                //         type: 'error'
                //     });
                //     return;
                // }
                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                // if (data.orderIds.length > 10) {
                //     this.mainLoading = false;
                //     this.$message({
                //         message: '一次最多导出10条记录，请分页逐页导出',
                //         showClose: true,
                //         offset: 200,
                //         type: 'error'
                //     });
                //     return;
                // }
                data.tmplId = this.exportTmplInfo.tmplId;
                data.exportType = this.exportTmplInfo.exportType;
                data.fileType = this.exportTmplInfo.fileType;
                data.exportImgSuffix = this.exportTmplInfo.exportImgSuffix;
                data.warterHeight = this.exportTmplInfo.warterHeight;
                this.mainLoading = true;
                this.mainLoadingText = '正在创建导出任务，请稍等';

                this.apis.postJson('user/enrollOrder/exportByTmpl', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.importEditorModal = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                        this.mainLoading = false;
                    }).catch(err => {
                    console.log(err);
                });

                // this.apis.axios()({
                //     method: 'post',
                //     url: 'user/enrollOrder/exportByTmpl',
                //     data: this.$http.adornParams(data),
                //     'responseType': 'blob'
                // }, false, 'json').then((res) => {
                //     if (res.type === 'application/json') {
                //         this.$message({
                //             message: '未查询到数据',
                //             showClose: true,
                //             offset: 200,
                //             type: 'error'
                //         });
                //         this.mainLoading = false;
                //         return false
                //     }
                //     let date = new Date();
                //     let year = date.getFullYear();
                //     let timestamp = date.valueOf();
                //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                //     this.mainLoading = false;
                //     this.exportTmplDialog = false
                // }).catch(err => {
                //     console.log(err);
                //     this.mainLoading = false;
                // });
                this.exportTmplDialog = false;
            },
            //导出证件照片对话框>确认导出
            toExportImg: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在创建导出任务，请稍等';
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                data.appendA = this.exportImgInfo.appendA;
                data.nameType = this.exportImgInfo.nameType;

                this.apis.postJson('user/enrollOrder/downloadImg', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.importEditorModal = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                        this.mainLoading = false;
                    }).catch(err => {
                    console.log(err);
                });
                this.exportImgModal = false;
                // this.apis.axios()({
                //     method: 'post',
                //     url: 'user/enrollOrder/downloadImg',
                //     data: this.$http.adornParams(data),
                //     'responseType': 'blob'
                // }, false, 'json').then((res) => {
                //     if (res.type === 'application/json') {
                //         this.$message({
                //             message: '未查询到数据',
                //             showClose: true,
                //             offset: 200,
                //             type: 'error'
                //         });
                //         this.mainLoading = false;
                //         return false
                //     }
                //     let date = new Date();
                //     let year = date.getFullYear();
                //     let timestamp = date.valueOf();
                //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                //     this.mainLoading = false;
                // }).catch(err => {
                //     console.log(err);
                //     this.mainLoading = false;
                // });
            },
            toExportEnrollImg: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出，请稍等';
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                if (!this.exportImgInfo.exportImgType) {
                    this.mainLoading = false;
                    this.$message({
                        message: '请选择导出的图片类型',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                if (this.exportImgInfo.exportImgType === '') {
                    if (!this.exportImgInfo.exportCertName) {
                        this.mainLoading = false;
                        this.$message({
                            message: '请选择证书类型',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        return;
                    }
                }
                if (!this.exportImgInfo.exportImgSuffix) {
                    this.mainLoading = false;
                    this.$message({
                        message: '请输入后缀字符',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }

                data.exportImgType = this.exportImgInfo.exportImgType;
                data.exportCertName = this.exportImgInfo.exportCertName;
                data.exportImgSuffix = this.exportImgInfo.exportImgSuffix;
                let url = 'user/enrollOrder/downloadEnrollImg'
                if (data.exportImgType ==='idcardImg') {
                    url = 'user/enrollOrder/exportIdcard'
                }
                this.mainLoadingText = '正在创建导出任务，请稍等';

                this.apis.postJson(url, data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.importEditorModal = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                        this.mainLoading = false;
                    }).catch(err => {
                    console.log(err);
                });

                // this.apis.axios()({
                //     method: 'post',
                //     url: url,
                //     data: this.$http.adornParams(data),
                //     'responseType': 'blob'
                // }, false, 'json').then((res) => {
                //     if (res.type === 'application/json') {
                //         this.$message({
                //             message: '未查询到数据',
                //             showClose: true,
                //             offset: 200,
                //             type: 'error'
                //         });
                //         this.mainLoading = false;
                //         return false
                //     }
                //     let date = new Date();
                //     let year = date.getFullYear();
                //     let timestamp = date.valueOf();
                //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                //     this.mainLoading = false;
                // }).catch(err => {
                //     console.log(err);
                //     this.mainLoading = false;
                // });
                this.exportEnrollImgModal = false;
            },
            toExportDefImg: function () {
                if (!this.queryParams.classId) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.apis.get('/course/class/getDefField?classId=' + this.queryParams.classId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.defFileList = res.data || []
                            this.exportDefImgModal = true;
                            // this.certNameList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            exportDefImg: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出，请稍等';
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                if (!this.exportImgInfo.exportImgType) {
                    this.mainLoading = false;
                    this.$message({
                        message: '请选择导出的类型',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                // if (!this.exportImgInfo.exportImgSuffix) {
                //     this.mainLoading = false;
                //     this.$message({
                //         message: '请输入后缀字符',
                //         showClose: true,
                //         offset: 200,
                //         type: 'error'
                //     });
                //     return;
                // }
                data.exportImgType = this.exportImgInfo.exportImgType;
                data.exportImgSuffix = this.exportImgInfo.exportImgSuffix;
                this.mainLoadingText = '正在创建导出任务，请稍等';
                let url = 'user/enrollOrder/downloadDefImg'
                this.apis.postJson(url, data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.exportDefImgModal = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                        this.mainLoading = false;
                    }).catch(err => {
                    console.log(err);
                });

                // this.apis.axios()({
                //     method: 'post',
                //     url: url,
                //     data: this.$http.adornParams(data),
                //     'responseType': 'blob'
                // }, false, 'json').then((res) => {
                //     if (res.type === 'application/json') {
                //         this.$message({
                //             message: '未查询到数据',
                //             showClose: true,
                //             offset: 200,
                //             type: 'error'
                //         });
                //         this.mainLoading = false;
                //         return false
                //     }
                //     let date = new Date();
                //     let year = date.getFullYear();
                //     let timestamp = date.valueOf();
                //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                //     this.mainLoading = false;
                // }).catch(err => {
                //     console.log(err);
                //     this.mainLoading = false;
                // });
            },
            //导出身份证
            toExportIdcard: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.queryParams.exportImgSuffix = ''
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }

                this.mainLoadingText = '正在创建导出任务，请稍等';

                this.apis.postJson('user/enrollOrder/exportIdcard', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.importEditorModal = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                        this.mainLoading = false;
                    }).catch(err => {
                    console.log(err);
                });

                // this.apis.axios()({
                //     method: 'post',
                //     url: 'user/enrollOrder/exportIdcard',
                //     data: this.$http.adornParams(data),
                //     'responseType': 'blob'
                // }, false, 'json').then((res) => {
                //     if (res.type === 'application/json') {
                //         this.$message({
                //             message: '未查询到数据',
                //             showClose: true,
                //             offset: 200,
                //             type: 'error'
                //         });
                //         this.mainLoading = false;
                //         return false
                //     }
                //     let date = new Date();
                //     let year = date.getFullYear();
                //     let timestamp = date.valueOf();
                //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                //     this.mainLoading = false;
                // }).catch(err => {
                //     console.log(err);
                //     this.mainLoading = false;
                // });
            },
            toImportJieyeCert: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.apis.axios()({
                    method: 'post',
                    url: 'user/enrollOrder/downloadCertImg',
                    data: this.$http.adornParams(data),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                    this.mainLoading = false;
                }).catch(err => {
                    console.log(err);
                    this.mainLoading = false;
                });
            },
            //导出银行卡
            toExportBankno: function () {
                let data = this.queryParams;
                if (!data.classId) {
                    this.$message({
                        message: '请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return
                }
                this.mainLoading = true;
                this.mainLoadingText = '正在导出，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'user/enrollOrder/exportBankno',
                    data: this.$http.adornParams(data),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    console.log(err);
                    this.mainLoading = false;
                });
            },
            //表格查询选项>获取班级列表
            getClassList: function () {
                if (!this.queryParams.companyId || !this.queryParams.levelId || !this.queryParams.gnvqId){
                    this.classList = [];
                    return;
                }
                let data = {
                    companyId: this.queryParams.companyId,
                    levelId: this.queryParams.levelId,
                    gnvqId: this.queryParams.gnvqId,
                    operUserId: this.appCookies.getCookieUID()
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //刷新列表
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            //获取列表
            initTable: function () {
                if (this.queryParams.startEndDate && this.queryParams.startEndDate.length >0) {
                    this.queryParams.startDate = this.queryParams.startEndDate[0];
                    this.queryParams.endDate = this.queryParams.startEndDate[1];
                } else {
                    this.queryParams.startDate = '';
                    this.queryParams.endDate = '';
                }
                this.tableLoading = true;
                this.apis.postForm('user/enrollOrder/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            //获取学历列表
            getEduList: function () {
                this.apis.get('system/dict/getList?typeCode=0003')
                    .then((res) => {
                        if (res.code === 1) {
                            this.eduList = res.data || []
                            // this.eduList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //鉴定机构录入
            toIdentify: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                let orderIds = [];
                this.selectdata.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.getIdentifyOrgList()
                this.identifyInfo = {};
                this.identifyInfo.orderIds = orderIds;
                this.identifyModel = true;
            },
            getIdentifyOrgList: function () {
                this.apis.get('system/dict/getList?typeCode=0006')
                    .then((res) => {
                        if (res.code === 1) {
                            this.identifyOrgList = res.data || []
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //鉴定机构录入对话框>确认保存
            identifySave: function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.identifyInfo.identifyDate === undefined || this.identifyInfo.identifyDate === null || this.identifyInfo.identifyDate ==='') {
                            this.$message({
                                message: '请选择报考日期',
                                showClose: true, offset: 200, type: 'success'
                            });
                            return;
                        }
                        let title = '确定录入鉴定机构报名信息？';
                        this.$confirm(title, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.identifyInfo.modifyBy = this.appCookies.getCookieUID();
                            this.identifyInfo.modifyByname = this.appCookies.getCookieUNAME();
                            this.apis.postJson('user/enrollOrder/updateIdentify', this.identifyInfo)
                                .then((res) => {
                                    if (res.code === 1) {
                                        this.$message({
                                            message: '操作成功',
                                            showClose: true, offset: 200, type: 'success'
                                        });
                                        this.identifyModel = false;
                                        this.initTable();
                                    } else {
                                        this.$message({
                                            message: res.msg,
                                            showClose: true, offset: 50, type: 'error'
                                        });
                                    }
                                }).catch(err => {
                                console.log(err);
                            });
                        }).catch(() => {
                        });
                    }
                });
            },
            //考试通知
            toExamRemain: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                let orderIds = [];
                this.selectdata.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.examRemindInfo = {};
                this.examRemindInfo.orderIds = orderIds;
                this.examModal = true;
            },
            //考试通知对话框>确认发送
            examRemainSave: function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let title = '确定发送考试通知信息？';
                        this.$confirm(title, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.apis.postJson('user/enrollOrder/examRemind', this.examRemindInfo)
                                .then((res) => {
                                    if (res.code === 1) {
                                        this.$message({
                                            message: '发送成功',
                                            showClose: true, offset: 200, type: 'success'
                                        });
                                        this.examModal = false;
                                    } else {
                                        this.$message({
                                            message: res.msg,
                                            showClose: true, offset: 50, type: 'error'
                                        });
                                    }
                                }).catch(err => {
                                console.log(err);
                            });
                        }).catch(() => {
                        });
                    }
                });
            },
            //删除
            toDelete: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                let orderIds = [];
                this.selectdata.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                let title = '确定要删除此条数据？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        orderIds: orderIds,
                        deleteStatus: 1,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postJson('user/enrollOrder/batchDelete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            //导入修改
            toImportUpdate: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                if (this.selectdata.length > 1) {
                    this.$message({
                        message: '只能选择一条记录进行修改',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                let item = this.selectdata[0];
                if (item.userId === item.createBy) {
                    this.$message({
                        message: '此功能只能修改导入的数据',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                this.apis.get('user/enrollOrder/getById?orderId=' + item.orderId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.editorInfo = res.data;
                            this.importEditorModal = true;
                        } else {
                            this.editorInfo = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //导入修改对话框>身份证号输入框（验证身份证号是否重复报名）
            checkHasEnroll: function () {
                let data = {
                    gnvqId: this.editorInfo.gnvqId,
                    levelId: this.editorInfo.levelId,
                    idCard: this.editorInfo.idCard,
                    orderId: this.editorInfo.orderId,
                }
                this.apis.get('user/enrollOrder/checkHasEnrollByIdCard', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$confirm('该身份证号已经报名过此工种等级,不能重复报名！', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                            }).catch(() => {
                            });
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //导入修改对话框>确认修改
            importUpdate: function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        // 保存数据
                        this.editorInfo.modifyBy = this.appCookies.getCookieUID();
                        this.editorInfo.modifyByname = this.appCookies.getCookieUNAME() ;
                        this.apis.postJson('user/enrollOrder/updateImport', this.editorInfo)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.importEditorModal = false;
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            //列表操作>查看/审核
            toView: async function (orderId) {
                this.$refs.userEnrollDtl.show(orderId);
            },
            showEditor: async function (orderId) {
                this.$refs.userEnrollEdtor.show(orderId);
            },
            closeEditor: function () {
                this.enrollEditorDrawer = false;
                this.toEdit(this.currentOrderId);
                this.initTable();
            },
            //报名信息Drawer>修改
            toEditor: function (orderId) {
                this.openDrawer = false;
                this.enrollEditorDrawer = true;
                this.currentOrderId = orderId;
            },

            getInfo: async function (orderId) {
                this.apis.get('user/enrollOrder/getById?orderId=' + orderId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.orderInfo = res.data;
                        } else {
                            this.orderInfo = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            /**
             * 文件流下载
             */
            downFile(content, fileName) {
                let blob = new Blob([content], {
                    type: 'application/vnd.ms-excel'
                });
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = objectUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
            dropdownValue: function (type, row) {
                return {
                    'type': type, //key 是你自己想要的字段，值自己定义
                    'data': row
                };
            },
            dropdownOperate: function (command) {
                console.log(command)
                if (command.type === 'export') {
                    this.toExport();
                } else if (command.type === 'exportpdf') {
                    this.toExportPdf();
                } else if (command.type === 'exportTmpl') {
                    this.toExportTmpl();
                } else if (command.type === 'exportCertImg') {
                    this.exportImgModal = true;
                } else if (command.type === 'exportIdcard') {
                    this.toExportIdcard();
                } else if (command.type === 'exportBankno') {
                    this.toExportBankno();
                } else if (command.type === 'toIdentify') {
                    this.toIdentify();
                } else if (command.type === 'toExamRemain') {
                    this.toExamRemain();
                } else if (command.type === 'toDelete') {
                    this.toDelete();
                } else if (command.type === 'toImportUpdate') {
                    this.toImportUpdate();
                } else if (command.type === 'exportJieyeImg') {
                    this.toImportJieyeCert();
                } else if (command.type === 'exportEnrollImg') {
                    this.exportEnrollImgModal = true;
                } else if (command.type === 'exportDefImg') {
                    this.toExportDefImg()

                } else if (command.type === 'classArchive') {
                    this.classArchive();
                } else if (command.type === 'userArchive') {
                    this.userArchive();
                } else if (command.type === 'exportByClass') {
                    this.exportByClassModal = true
                }

            },
            // 班级学习档案
            classArchive: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.apis.axios()({
                    method: 'post',
                    url: 'user/enrollOrder/classArchive',
                    data: this.$http.adornParams(data),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    console.log(err);
                    this.mainLoading = false;
                });
            },
            // 班级学习档案
            userArchive: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                let data = this.queryParams;
                data.orderIds = this.getSelectionIds();

                if (!this.queryParams.classId && data.orderIds.length === 0) {
                    this.mainLoading = false;
                    this.$message({
                        message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.apis.axios()({
                    method: 'post',
                    url: 'user/enrollOrder/userArchive',
                    data: this.$http.adornParams(data),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                    this.mainLoading = false;
                }).catch(err => {
                    console.log(err);
                    this.mainLoading = false;
                });
            },
            //获取证书类型下拉菜单列表
            getCertNameList: function () {
                if (this.exportImgInfo.exportImgType === 'certImg') {
                    this.apis.get('system/dict/getList?typeCode=0005')
                        .then((res) => {
                            if (res.code === 1) {
                                this.certNameList = res.data || []
                                // this.certNameList.splice(0, 0, { dictId: '', dictName: '请选择' });
                            } else {
                                console.log('发生错误:' + res.msg);
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                    this.exportImgInfo.exportImgSuffix = "-证书照片"
                } else if (this.exportImgInfo.exportImgType === 'certHeadImg') {
                    this.exportImgInfo.exportImgSuffix = "-蓝底照片"
                } else if (this.exportImgInfo.exportImgType === 'idcardImgFront') {
                    this.exportImgInfo.exportImgSuffix = "-身份证正面"
                } else if (this.exportImgInfo.exportImgType === 'idcardImgBack') {
                    this.exportImgInfo.exportImgSuffix = "-身份证反面"
                } else if (this.exportImgInfo.exportImgType === 'idcardImg') {
                    this.exportImgInfo.exportImgSuffix = "-身份证照片"
                } else if (this.exportImgInfo.exportImgType === 'workImg') {
                    this.exportImgInfo.exportImgSuffix = "-工龄证明"
                } else if (this.exportImgInfo.exportImgType === 'eduImg') {
                    this.exportImgInfo.exportImgSuffix = "-学历证明"
                }
            },
            tmplIdChnage: function (val) {
                let curr = this.exportTmplList.filter((p) => {
                    return p.tmplId === val;
                });
                this.exportTmplInfo.exportImgSuffix = '-' + curr[0].tmplName
            }
        }
    }
</script>
<style lang="scss" scoped>
.table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 10px 0;
    .left {
        height: 100px;
        width: 140px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }

    .center {
        height: 120px;
        flex: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: #999999 dotted 1px;
            padding: 4px 4px;
            background-color: #eef6ff;
            .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                //margin-bottom: 6px;
                .label {
                    text-align: left;
                    font-weight: bold;
                    min-width: 60px;
                    flex-shrink: 0;
                }
                .value {
                    text-align: left;
                    padding-left: 10px;
                    min-width: 60px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .strong {
                    font-weight: bold;
                }
            }
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: row;
            //justify-content: space-between;
            .item-info {
                height: 100%;
                padding: 0 10px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .label {
                        min-width: 60px;
                        text-align: right;
                        font-weight: bold;
                    }
                    .value {
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .strong {
                        font-weight: bold;
                    }
                }
            }
        }

        .footer {

        }
    }
}

.oper-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
}
::v-deep {
    .el-radio {
        line-height: 2;
    }
}
</style>
